$fi-path: "../icons";
@import "../node_modules/foundation-icon-fonts/_foundation-icons.scss";
@import "../node_modules/foundation-sites/scss/foundation.scss";

@import "../node_modules/select2/src/scss/core.scss";
@import "settings";

@include foundation-everything;
body {
  overflow-y: hidden;
}
.move-back {
  z-index: -10;
}
.gridster {
  &#gridster-content {
    .gs-w {
      cursor: move;
    }
  }
  .gs-w {
    outline: 5px solid transparent;
    transition: 0.25s border ease-in;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: transparent;
      transition: 0.25s background-color ease-in;
    }
    &:hover {
      &:after {
        background-color: fade-out($sidebar-bg-color, 0.5);
      }
    }
    img {
      width: 100%;
    }
    overflow-y: hidden;
  }
  &.dragging {
    ul {
      outline: 5px dashed fade-out($medium-gray, 0.5);
    }
    .gs-w:not(.dragging) {
      outline: 5px solid fade-out($medium-gray, 0.5);
    }
  }
  ul {
    margin: 0 auto;
    &.block-placeholder {
      min-height: $placeholder-height;
      text-align: center;
      color: darken($medium-gray, 20%);
      cursor: pointer;
      transition: all 0.25s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e3e3e3;
      &:before {
        @include button();
        margin: 0;
        font-size: rem-calc(12);
        content:  "SELECT BODY";
      }
    }
  }
  &#gridster-header .block-placeholder{
    background: #f2f2f2;
    &:before {
      content:  "SELECT HEADER";
    }
  }
  &#gridster-footer .block-placeholder{
    background: #ececec;
    &:before {
      content:  "SELECT FOOTER";
    }
  }
}

.button.alert {
  color: $white;
  &:hover {
    color: $white;
  }
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $sidebar-bg-color;
  width: $sidebar-width;
  padding: $global-padding 0;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .menu {
    li {
      &.title  {
        padding-left: $global-padding;
        background-color: transparent;
        margin-top: 1rem;
        label {
          color: $primary-color;
          text-transform: uppercase;
          font-weight: $global-weight-bold;
          span[data-tooltip] {
            margin-left: rem-calc(3);
          }
        }
      }
    }
  }
  button {
    @include button($background: transparent, $background-hover: transparent, $expand: true);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      @include css-triangle(5px, white, right);
    }
    border-radius: $global-radius;
    &:hover {
      background-color: $sidebar-anchor-bg-hover;
    }
    text-align: left;
    margin: 0;
  }
  select {
    color: $mineshaft;
  }
  button, select {
    outline: none;
  }
}
.select {
  &--theme, &--compliance, &--country {
    padding: 0 $global-padding;
  }
}
.nested-sidebar {
  top: 0;
  opacity: 0;
  transition: 0.25s ease-in opacity;
  bottom: 0;
  position: fixed;
  background-color: $sidebar-bg-color;
  border: 0;
  display: none;
  padding: $global-padding;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  &.aw-open {
    left: $sidebar-width;
    display: block;
    opacity: 1;
  }
  p.block-title {
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(5);
    font-size: rem-calc(14);
    max-width: $nested-sidebar-width;
  }
  .column {
    .block-image {
      position: relative;
      max-width: $nested-sidebar-width;
    }
    .buttons {
      opacity: 0;
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) opacity;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .button {
        transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1) box-shadow;
        box-shadow: 0 0 11px 0 transparentize($black, 0.5);
        text-transform: uppercase;
        margin: 0;
      }
      &:hover {
        opacity: 1;
        .button {
          box-shadow: 0 0 11px 152px transparentize($black, 0.5);
        }
      }
    }
  }
}

.nested-nested-sidebar {
  position: fixed;
  z-index: 25;
  display: none;
  bottom: 0;
  top: 0;
  &.aw-open {
    left: calc(#{$sidebar-width + $nested-sidebar-width} + #{$global-padding});
    display: block;
    opacity: 1;
  }
  .pane {
    display: none;
    background-color: $sidebar-bg-color;
    text-align: left;
    max-width: $preview-pane-width;
    position: fixed;
    box-shadow: $maincontent-shadow;
    &.aw-open {
      display: block;
    }
  }
}

.content {
  margin-left: $sidebar-width;
  position: relative;
  height: calc(100vh - 68px);
  overflow-y: scroll;
  .header {
    text-align: center;
    color: $secondary-color;
    padding: $global-padding;
    margin: 25px 0;
    h1 {
      font-size: 2rem;
      font-weight: 300;
      strong {
        font-weight: 700;
      }
    }
    p {
      max-width: 650px;
      margin: 0 auto;
    }
    img {
      height: auto;
      width: 18px;
      vertical-align: text-bottom;
    }
  }
  #ala-browser {
    width: $contentWidth;
    height: 2rem;
    background: #ccc;
    margin: 0 auto;
    color: $white;
    &:before {
      content: "\2022\2022\2022";
      font-size: 2rem;
      line-height: 1.6rem;
      padding-left: .4rem;
    }
  }
}
.controls {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  opacity: 0;
  .gs-w:hover & {
    opacity: 1;
  }
  .button {
    margin-top: 1rem;
    &:active, &:hover {
      color: $white;
    }
  }
}

.build-it {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - #{$sidebar-width});
  z-index: 10;
  background: #fff;
  .block-wrapper {
    margin: 0 auto;
    width: $contentWidth;
    padding: .7rem .3rem;
    text-align: center;
    button.button {
      margin: 0;
      background-color: $aw-green;
    }
  }
}

.add-widget {
  width: auto;
}

div#registrationModal {
  p {
    margin-bottom: .65rem;
  }
  .header {
    text-align: center;
    color: $secondary-color;
    margin-bottom: 1rem;
    .aw-moose {
      width: 7rem;
    }
  }
  .main-form {
    max-width: 30rem;
    color: #555;
    margin: auto;
  }
  input {
    max-width: 10rem;
    display: inline-block;
    border: 0;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
  }
  div.g-recaptcha {
    display: inline-block;
  }
  #save-user {
    background: $aw-green;
    color: $white;
    margin-top: 1rem;
    display: block;
    margin: 0 auto;
  }

}

.thumbnail {
  // Override image width for now until we get smaller image thumbnails.
  max-width: $thumbnail-width;
}
.sidebar {
  .description {
    font-size: $description-font-size;
    margin-bottom: 1rem;
    li {
      text-align: left;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.nested-sidebar {
  > .description {
    color: white;
    text-align: justify;
  }
  > .column {
    clear: both;
  }
}
.img-logo {
  padding: 20px;
  text-align: center;
  img {
    width: 75%;
  }
}

.menu.vertical {
  .title {
    .has-tip {
      color: $white;
      border: 0;
    }
  }
}

.select2-results__option {
  font-size: 0.8rem;
}

.select2-container--default {
  .select2-results__option[aria-disabled=true] {
    display: none;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $sidebar-bg-color;
  }
  .select2-dropdown {
    margin-top: 10px;
    border-radius: 4px;
    &::before {
      @include css-triangle(5px, white, up);
      display: inline-block;
      top: -5px;
      right: 3px;
      position: absolute;
    }
  }
  &.select2-container--open.select2-container--below {
    border-radius: 4px;
    .select2-selection--single, .select2-selection--multiple {
      border-radius: $button-radius;
    }
  }
  .select2-selection--single, .select2-selection--multiple {
    border-radius: $button-radius;
    height: $select-height;
    .select2-selection__arrow {
      height: $select-height;
    }
    .select2-selection__rendered {
      line-height: $select-height;
    }
  }
}

